<template>
  <v-card class="bordered mx-auto" max-width="900">
    <div class="card-container mx-8 py-4">
      <v-card-title>
        <v-col class="name">
          {{ $t('profile_statistics_title') }}
        </v-col>
      </v-card-title>
      <v-divider class="divider mx-4" />
      <v-row class="profile-statistics">
        <v-col v-for="chart in charts" :key="chart.title">
          <chart-card :data="chart.data"
                      :options="chart.options"
                      :responsive-options="chart.responsiveOptions"
                      :title="chart.title"
                      :type="chart.type"
          />
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
import ChartCard from '@/components/Core/ChartCard'
import { mapActions } from 'vuex'

export default {
  name: 'ProfileStatistics',
  components: {
    ChartCard
  },
  data: () => ({
    statistics: {
      plants_chart: {},
      balance_chart: {}
    }
  }),
  mounted () {
    this.loadProfileStatistic()
      .then(result => {
        this.statistics = result.payload
      })
  },
  computed: {
    pieChartData () {
      let labels = [], series = []
      Object.keys(this.statistics.plants_chart).forEach(k => {
        labels.push(this.statistics.plants_chart[k].name)
        series.push(this.statistics.plants_chart[k].qty)
      })
      return { labels, series }
    },
    monthlyEarningData () {
      let labels = [], qty = [], amount = [], investment = []
      Object.keys(this.statistics.balance_chart).forEach(k => {
        labels.push(k)
        qty.push(this.statistics.balance_chart[k].qty)
        investment.push(this.statistics.balance_chart[k].initial_investment)
        amount.push(this.statistics.balance_chart[k].total_price)
      })
      return { labels, series: [qty, investment, amount] }
    },
    charts () {
      return [
        {
          type: 'Pie',
          title: 'Plants',
          subtitle: 'Daily Auction Performance',
          time: 'updated yesterday',
          data: this.pieChartData,
          options: {
            labelInterpolationFnc: function(value) {
              return value[0]
            }
          },
          responsiveOptions: [
            ['screen and (min-width: 640px)', {
              chartPadding: 30,
              labelOffset: 40,
              labelDirection: 'explode',
              labelInterpolationFnc: function(value) {
                return value
              }
            }],
            ['screen and (min-width: 1024px)', {
              labelOffset: 40,
              chartPadding: 20
            }]
          ],
        },
        {
          type: 'Line',
          title: 'Auction Daily',
          subtitle: 'Daily Auction Performance',
          time: 'updated yesterday',
          data: this.monthlyEarningData,
          options: {
            axisX: {
              showGrid: false,
            },
            axisY: {
              showGrid: false,
              labelInterpolationFnc: function(value) {
                return (value / 1000) + 'k';
              }
            },
            low: this.auctionDailyLow,
            high: this.auctionDailyHigh,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
      ]
    }
  },
  methods: {
    ...mapActions({
      loadProfileStatistic: 'statistics/loadProfileStatistic'
    })
  }
}
</script>
<style lang="scss" scoped>
.bordered {
  border: 1px solid #BDE5DD;
  margin-bottom: 32px;
}

.name {
  color: #009357;
  font-weight: bold;
  font-size: 1.5rem
}

.card-container {
  margin: auto;
}

.profile-statistics {
  padding-top: 16px;

  & .col {
    max-height: 206px;
  }
}

@media screen and (max-width: 768px) {
  .bordered {
    margin: 0;
  }
}

@media screen and (max-width: 428px) {
  .name {
    font-size: 18px;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .profile-statistics .v-card__title {
    word-break: unset;
  }
}
</style>
