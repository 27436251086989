<template>
  <v-dialog v-model="dialog" max-width="900">
    <v-card>
      <v-card-title class="text-h5">
        {{ $t('greenhouse_camera_title') }}
      </v-card-title>

      <div class="camera-container">
        <loader v-if="cameraLoading" />
        <canvas ref="canvas" />
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Loader from '@/components/Core/Loader'
import { loadPlayer } from '@/plugins/rtsp_brower'
import * as configLocal from '@/config/local.json'

export default {
  name: 'CameraDialog',
  components: {
    Loader
  },
  data: () => ({
    dialog: false,
    cameraLoading: true
  }),
  mounted () {
    let t = setInterval(() => {
      if (this.$refs && this.$refs.canvas) {
        loadPlayer({
          url: configLocal.default.camera.stream_uri,
          canvas: this.$refs.canvas,
        })
          .then(() => {
            this.cameraLoading = false
          })
        clearInterval(t)
      }
    }, 500)
  },
  methods: {
    open () {
      this.dialog = true
    }
  }
}
</script>
<style scoped>
.camera-container {
  display: flex;
  min-height: 250px;
}
.camera-container canvas {
  width: 95%;
  margin: auto;
}
</style>
