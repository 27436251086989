<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="4" class="office-plants-gallery">
        <plants-gallery />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="8">
        <v-card class="bordered mx-auto" max-width="900">
          <div class="card-container py-4">
            <v-card-title>
              <v-col class="name">
                {{ user.name }}
              </v-col>
              <v-col class="text-right">
                <v-icon @click="goToProfileSettings" class="profile-settings-button" color="#649187">mdi-cog</v-icon>
              </v-col>
            </v-card-title>
            <v-divider class="divider mx-4" />
            <v-row class="profile">
              <v-col>
                <div class="v-card__title">
                  <v-row>
                    <v-col class="profile-icon" cols="12" sm="2" md="2" lg="2">
                      <v-img src="@/assets/icons/profile.svg" height="28" width="28" />
                    </v-col>
                    <v-col class="profile-data" cols="12" sm="10" md="10" lg="10">
                      <div class="d-block profile-title">{{ $t('email') }}:</div>
                      <div class="d-block profile-value">{{ user.email }}</div>
                    </v-col>
                  </v-row>
                </div>
                <div class="v-card__title">
                  <v-row>
                    <v-col class="profile-icon" cols="12" sm="2" md="2" lg="2">
                      <v-img src="@/assets/icons/map.svg" height="28" width="28" />
                    </v-col>
                    <v-col class="profile-data" cols="12" sm="10" md="10" lg="10">
                      <div class="d-block profile-title">{{ $t('country') }}:</div>
                      <div class="d-block profile-value">{{ getCountryName }}</div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col>
                <div class="v-card__title">
                  <v-row>
                    <v-col class="profile-icon" cols="12" sm="2" md="2" lg="2">
                      <v-img src="@/assets/icons/phone.svg" height="28" width="28" />
                    </v-col>
                    <v-col class="profile-data" cols="12" sm="10" md="10" lg="10">
                      <div class="d-block profile-title">{{ $t('phone_number') }}:</div>
                      <div class="d-block profile-value">{{ user.phone_number }}</div>
                    </v-col>
                  </v-row>
                </div>
                <div class="v-card__title">
                  <v-row>
                    <v-col class="profile-icon" cols="12" sm="2" md="2" lg="2">
                      <v-img src="@/assets/icons/marker.svg" height="28" width="28" />
                    </v-col>
                    <v-col class="profile-data" cols="12" sm="10" md="10" lg="10">
                      <div class="d-block profile-title">{{ $t('city') }}:</div>
                      <div class="d-block profile-value">{{ user.city }}</div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <profile-statistics />
        <v-card class="mx-auto green-house" max-width="900">
          <div class="card-container py-4">
            <v-card-title style="font-size: 1.5rem">{{ $t('smart_greenhouse_title') }}</v-card-title>
            <v-row>
              <v-col>
                <div class="v-card__title item">
                  <div class="icon"><v-img src="@/assets/icons/sun.svg" height="30" width="30" /></div>
                  <span class="title">{{ $t('temperature') }}</span><span class="value">{{ sensors.temperature }}&#8451;</span>
                </div>
                <div class="v-card__title item">
                  <div class="icon"><v-img src="@/assets/icons/oil.svg" height="30" width="30" /></div>
                  <span class="title">{{ $t('watering') }}</span> <span class="value" style="font-size: 12px;">{{ watering }}</span>
                </div>
              </v-col>
              <v-col>
                <div class="v-card__title item">
                  <div class="icon"><v-img src="@/assets/icons/lightning.svg" height="30" width="30" /></div>
                  <span class="title">{{ $t('feeding') }}</span> <span class="value" style="font-size: 12px;">{{ feeding }}</span>
                </div>
                <div class="v-card__title item">
                  <div class="icon"><v-img src="@/assets/icons/humidity.svg" height="30" width="30" /></div>
                  <span class="title">{{ $t('humidity') }}</span> <span class="value">{{ sensors.humidity }}</span>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card class="mx-auto" max-width="900">
          <div class="card-container my-8 py-4">
            <v-row class="green-house-buttons">
              <v-col>
                <v-btn @click.native="$refs.cameraDialog.open" color="primary" x-large elevation="3">
                  {{ $t('your_greenhouse_button') }}
                </v-btn>
              </v-col>
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on">
                      <v-btn color="primary" x-large disabled elevation="3" v-bind="attrs">
                        {{ $t('phyto_exchange') }}
                      </v-btn>
                    </div>
                  </template>
                  <span>{{ $t('phyto_exchange_tooltip') }}</span>
                </v-tooltip>
              </v-col>
              <v-col>
                <v-row class="social-icons">
                  <v-col>
                    <a href="https://www.facebook.com/grower_invest-107352865442627/" target="_blank">
                      <v-img height="32" width="32" src="@/assets/icons/facebook.svg" alt="Facebook" />
                    </a>
                  </v-col>
                  <v-col>
                    <a href="https://www.instagram.com/grower_invest/" target="_blank">
                      <v-img height="32" width="32" src="@/assets/icons/instagram.svg" alt="Instagram" />
                    </a>
                  </v-col>
                  <v-col>
                    <a href="https://twitter.com/Grower_invest" target="_blank">
                      <v-img height="32" width="32" src="@/assets/icons/twitter.svg" alt="Twitter" />
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <camera-dialog ref="cameraDialog" />
  </v-container>
</template>
<script>
import PlantsGallery from '@/components/Office/PlantsGallery'
import ProfileStatistics from '@/components/Office/ProfileStatistics'
import CameraDialog from '@/components/Greenhouse/CameraDialog'
import { mapActions, mapGetters } from 'vuex'
import { format, register } from 'timeago.js'
import { uk } from 'timeago.js/lib/lang/index'

register('uk', uk)

export default {
  name: 'Office',
  components: {
    PlantsGallery,
    ProfileStatistics,
    CameraDialog
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    }),
    getCountryName () {
      if (!this.user) {
        return ''
      }
      return this.countries[this.user.country] || ''
    },
    plant () {
      if (!this.inventory.length) {
        return false
      }
      return this.inventory[this.inventory.length - 1]
    },
    watering () {
      if (!this.sensors.watering) {
        return null
      }

      return format(this.sensors.watering, 'uk')
    },
    feeding () {
      // The current day
      let date = new Date();
      let now = date.getDay();

      // Days of the week
      let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

      // The index for the day you want
      let day = days.indexOf('wednesday');

      // Find the difference between the current day and the one you want
      // If it's the same day as today (or a negative number), jump to the next week
      let diff = day - now;
      diff = diff < 1 ? 7 + diff : diff;

      // Get the timestamp for the desired day
      let nextDayTimestamp = date.getTime() + (1000 * 60 * 60 * 24 * diff);

      // Get the next day
      // return new Date(nextDayTimestamp);

      return format(new Date(nextDayTimestamp), 'uk')
    }
  },
  beforeMount () {
    document.title = 'Grower Exchange | Кабінет інвестора'
    this.loadSensors()
      .then(result => {
        this.sensors = result.data
      })
  },
  data: () => ({
    countries: {
      ua: 'Україна',
      ukraine: 'Україна'
    },
    sensors: {}
  }),
  methods: {
    ...mapActions({
      loadSensors: 'smart_house/loadSensorsData'
    }),
    goToProfileSettings () {
      this.$router.push('/me/profile-settings')
    }
  }
}
</script>
<style scoped>
.card-container {
  width: 80%;
  margin: auto;
}

.name {
  color: #009357;
  font-weight: bold;
  font-size: 1.5rem
}

.profile-title {
  color: #DAF0E9;
}

.profile-value {
  font-size: 16px;
}

.profile-settings-button {
  cursor: pointer;
}

.bordered {
  border: 1px solid #BDE5DD;
  margin-bottom: 32px;
}

.green-house {
  background-color: #0BCA7C;
}

.green-house .v-card__title {
  color: #FFFFFF;
  padding: 20px 0px;
  font-weight: 700;
}

.green-house .item {
  font-size: 14px;
  padding: 30px;
  background-color: rgba(218, 240, 233, .3);
  margin-bottom: 25px;
  border-radius: 15px;
}

.green-house .item .icon {
  padding-right: 10px;
  width: 30px;
  height: 30px;
}

.green-house .item .title {
  flex: 1 1;
}

.green-house .item .value {
  font-size: 24px;
  font-weight: 700;
  align-self: end;
}

.social-icons {
  margin: auto;
}

@media screen and (max-width: 768px) {
  .bordered {
    margin: 0;
  }

  .green-house {
    margin-top: 24px;
  }
}

@media screen and (max-width: 428px) {
  .name {
    font-size: 18px;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .profile .v-card__title {
    word-break: unset;
  }

  .profile-icon {
    flex: 0 0 19%;
    max-width: 19%;
  }

  .profile-data {
    flex: 0 0 81%;
    max-width: 81%;
  }

  .green-house .row {
    flex-direction: column;
  }

  .green-house-buttons {
    flex-direction: column;
    text-align: center;
  }

  .social-icons {
    justify-content: center;
  }

  .social-icons > * {
    flex: 0;
  }
}
</style>
