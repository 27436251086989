<template>
  <div class="loading-spinner" :class="{ 'in-block': inBlock }" />
</template>
<script>
export default {
  name: 'Loader',
  props: {
    inBlock: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
<style lang="scss" scoped>
.loading-spinner {
  position: fixed;
  left: 50%;
  transform: translate(-50%,-50%) translateZ(0);
  top: 50%;
  width: 90px;
  height: 90px;
  z-index: 1;
  background: url('../../assets/logo.png') center no-repeat;
  background-size: contain;

  &:after {
    content:"";
    position:absolute;
    width:120px;
    height:120px;
    background-color:rgba(0,0,0,0);
    border-radius:100%;
    margin:-15px;
    box-shadow: 0 4px 0 0 #009357;
    transition: all 1s linear;
    animation: lds-eclipse 1s linear infinite;
  }

  &.in-block {
    position: relative;

    &:after {
      margin-left: -60px;
    }
  }
}

.container .loading-spinner.in-block {
  margin: auto;
  left: unset;
  transform: unset;

  &:after {
    margin: -15px;
  }
}

@keyframes spinning {
  0% { transform: translate(-50%,-50%) scale(1) translateZ(0);}
  50% { transform: translate(-50%,-50%) scale(1.1) translateZ(0);}
  100% { transform: translate(-50%,-50%) scale(1) translateZ(0);}
}

@keyframes lds-eclipse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
