<template>
  <v-card class="v-card--chart" full-header>
    <div class="pa-4">
      <chartist
          :data="data"
          :event-handlers="eventHandlers"
          :options="options"
          :ratio="ratio"
          :responsive-options="responsiveOptions"
          :type="type"
          style="max-height: 150px;"
      />
    </div>
  </v-card>
</template>

<script>
  export default {
    name: 'ChartCard',

    inheritAttrs: false,

    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      eventHandlers: {
        type: Array,
        default: () => ([]),
      },
      options: {
        type: Object,
        default: () => ({}),
      },
      ratio: String,
      responsiveOptions: {
        type: Array,
        default: () => ([]),
      },
      type: {
        type: String,
        required: true,
        validator: v => ['Bar', 'Line', 'Pie'].includes(v),
      },
    },
  }
</script>

<style lang="scss">
.v-card--chart {
  background-color: #0BCA7C!important;
  color: #FFFFFF!important;
  font-weight: 600;

  & .ct-label {
    color: #FFFFFF!important;
    fill: #FFFFFF!important;
  }

  & .ct-series-a .ct-point,
  & .ct-series-a .ct-line,
  & .ct-series-a .ct-bar,
  & .ct-series-a .ct-slice-donut {
    stroke: #FFFFFF!important;
  }

  & .ct-series-a .ct-slice-pie,
  & .ct-series-a .ct-area {
    fill: rgba(255,255,255,.4)
  }
}
</style>
