<template>
    <v-card class="item-card">
      <v-img :src="itemImage" contain max-height="650" />

      <div class="details">
        <div class="v-card__title item-title">
          {{ item.name }}
          <span class="price">${{ item.base_price_per_unit || 0 }}</span>
        </div>

        <v-divider class="divider" />

        <div class="item-properties">
          <div class="v-card__title property">{{ $t('current_price') }} - ${{ item.current_price_per_unit || 0 }}/шт.</div>
          <div class="v-card__title property">{{ $t('qty_owned') }}: {{ item.qty }} шт.</div>
          <div class="v-card__title property">{{ $t('total_earning') }}: ${{ item.total_earnings }}</div>
          <div class="v-card__title property">{{ $t('total_current_price') }}: ${{ item.total_current_price }}</div>
        </div>
      </div>
    </v-card>
</template>
<script>
export default {
  name: 'GalleryItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    itemImage () {
      if (!this.item.current_stage || !this.item.current_stage.image_url) {
        return ''
      }

      return this.item.current_stage.image_url
    },
  }
}
</script>
<style scoped>
.item-title {
  font-style: normal;
  line-height: 100%;
  font-size: 24px;
  font-weight: 500;
  background-color: #0BCA7C;
  border-radius: 15px;
  color: #FFFFFF;
  padding: 25px;
  margin-bottom: 25px;
}

.item-title .price {
  font-weight: 700;
  margin-left: auto;
  padding-right: 30px;
}

.details {
  width: 80%;
  margin: auto;
}

.item-properties .property {
  color: rgba(100, 145, 135, 0.7);
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  padding: 16px 0;
}

.item-card {
  border: 1px solid #BDE5DD;
  margin-bottom: 32px;
  width: 467px;
}

@media screen and (max-width: 768px) {
  .item-card {
    margin: 0;
  }
}
</style>
