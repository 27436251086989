<template>
  <v-slide-group>
    <v-slide-item v-for="item in inventory" :key="item.id" disabled>
      <gallery-item :item="item" />
    </v-slide-item>
  </v-slide-group>
</template>
<script>
import GalleryItem from '@/components/Office/PlantsGallery/GalleryItem'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PlantsGallery',
  components: {
    GalleryItem
  },
  beforeMount () {
    this.loadingInventory = true
    this.loadInventory(this.token)
      .finally(() => {
        this.loadingInventory = false
      })
  },
  data: () => ({
    loadingInventory: false,
    show: false
  }),
  computed: {
    ...mapGetters({
      inventory: 'user/inventory/getInventory'
    })
  },
  methods: {
    ...mapActions({
      loadInventory: 'user/inventory/loadInventory'
    })
  }
}
</script>
